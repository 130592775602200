/* example style variables that can be defined to reuse */
$icon-size:50px;
$dark-overlay:rgba(0,0,0,0.7);

/* App Variables for Colors and Styles */
$vc-blue: #0070c0;
$vc-green: #4f9c51;

$vc-highlight: #ffffa8;

$vc-blue-light95: #e6f4ff;
$vc-green-light95: #eef6ee;

$vc-blue-boxshadow: rgba($vc-blue, 0.32);
$vc-green-boxshadow: rgba($vc-green, 0.32);

$vc-brown: #663300;

$vc-background: #E8E8E8;

$md-vc-blue: (
    50 : #e0eef7,
    100 : #b3d4ec,
    200 : #80b8e0,
    300 : #4d9bd3,
    400 : #2685c9,
    500 : #0070c0,
    600 : #0068ba,
    700 : #005db2,
    800 : #0053aa,
    900 : #00419c,
    A100 : #c7daff,
    A200 : #94b8ff,
    A400 : #6197ff,
    A700 : #4786ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-vc-green: (
    50 : #eaf3ea,
    100 : #cae1cb,
    200 : #a7cea8,
    300 : #84ba85,
    400 : #69ab6b,
    500 : #4f9c51,
    600 : #48944a,
    700 : #3f8a40,
    800 : #368037,
    900 : #266e27,
    A100 : #b2ffb3,
    A200 : #7fff80,
    A400 : #4cff4e,
    A700 : #32ff35,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-vc-red: (
    50 : #f8e5e5,
    100 : #eebfbf,
    200 : #e39494,
    300 : #d76969,
    400 : #cf4848,
    500 : #c62828,
    600 : #c02424,
    700 : #b91e1e,
    800 : #b11818,
    900 : #a40f0f,
    A100 : #ffd4d4,
    A200 : #ffa1a1,
    A400 : #ff6e6e,
    A700 : #ff5454,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
