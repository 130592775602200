@use '@angular/material' as mat;
@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500');

@include mat.core();
@import 'stylevars.scss';

// $vc-app-primary: mat.define-palette(mat.$blue-palette, 800);
// $vc-app-accent: mat.define-palette(mat.$green-palette, A700);

$vc-app-primary: mat.define-palette($md-vc-blue);
$vc-app-accent: mat.define-palette($md-vc-green);

// The warn palette is optional (defaults to red).
$vc-app-warn: mat.define-palette($md-vc-red);

// Create the theme object (a Sass map containing all of the palettes).
$vc-app-theme: mat.define-light-theme($vc-app-primary, $vc-app-accent, $vc-app-warn);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$vc-typography: mat.define-typography-config(
  $font-family:   'Nunito, "Helvetica Neue", sans-serif',
  $display-4:     mat.define-typography-level(112px, 112px, 300),
  $display-3:     mat.define-typography-level(56px, 56px, 400),
  $display-2:     mat.define-typography-level(45px, 48px, 400),
  $display-1:     mat.define-typography-level(34px, 40px, 400),
  $headline:      mat.define-typography-level(24px, 32px, 400),
  $title:         mat.define-typography-level(20px, 32px, 500),
  $subheading-2:  mat.define-typography-level(16px, 28px, 400),
  $subheading-1:  mat.define-typography-level(15px, 24px, 400),
  $body-2:        mat.define-typography-level(16px, 24px, 500),
  $body-1:        mat.define-typography-level(14px, 20px, 400),
  $caption:       mat.define-typography-level(12px, 20px, 400),
  $button:        mat.define-typography-level(14px, 14px, 500),
  $input:         mat.define-typography-level(16px, 1.125, 400)
);

@mixin mix-app-theme($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);
  $warn: map-get($app-theme, warn);

  .mat-button-toggle {
    background-color: inherit;
  }

  .mat-button-toggle-checked {
    background-color: white;
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content > .vc-list-toolbar-icon {
    color: $vc-blue;
  }

  .mat-button-toggle-label-content {
    line-height: 34px !important;
    padding: 0 12px !important;
  }
}

// Override the typography in the core CSS.
@include mat.core($vc-typography);

// Set the app material design theme
@include mat.all-component-themes($vc-app-theme);

// Include the mixin
@include mix-app-theme($vc-app-theme);
