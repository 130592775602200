@import 'stylevars.scss';

/* Fix for Google Visualization Charts
https://github.com/google/google-visualization-issues/issues/2162 */
svg > g > g:last-child { pointer-events: none }

/* You can add global styles to this file, and also import other style files */

/* VacationCounts Logo */
.vc-blue {
  color: $vc-blue;
}
.vc-green {
  color: $vc-green;
}

/* Navbar styles for get blue background and light text - built in color schemes not working */
.navbar-vc {
  background-color: #0070c0;
  padding: 6px 0 0 10px;
}
/* change the brand and text color */
.navbar-vc .navbar-brand,
.navbar-vc .navbar-text {
  color: white;
}
/* change the link color */
.navbar-vc .nav-item .nav-link {
  color: white;
}
/* change the color of active or hovered links */
.navbar-vc .nav-item.active .nav-link {
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #4f9c51;
}
.navbar-vc .nav-item:hover .nav-link {
  color: rgba(252, 255, 103, 0.904);
}

.list-group-item.active > a {
  color: black;
  font-weight: bold;
}
.list-group-item.active > span {
  background-color:black;
}

.year-input {
  width: 95px;
}

.v-thumbnail {
  display: inline-block;
}

img.v-thumbnail-size {
  min-width: 250px;
  min-height: 250px;
  text-align: center;
}

.photo-polaroid {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 10px 55px 10px;
  background-color: white;
  margin: 5px 15px 5px 10px;
}

.photo-boxshadow {
  box-shadow: 0px 1px 3px 0px $vc-green-boxshadow;
  text-align: center;
  padding: 12px 12px 50px 12px;
  width: auto;
  height: auto;
  background-color: white;
}

.vc-vacation-photo {
  max-width: 360px;
  width: 100%;
  height: auto;
}

.desttypeImg {
  margin-right: 12px;
  margin-bottom: 8px;
}

.destCountryImg {
  padding: 8px;
  text-align: center;
}

.vacation-header {
  background-color: $vc-green;
  color: white;
  margin-top: 0px;
  margin-bottom: 8px;
  margin-left: -6px;
  margin-right: -6px;
}


.v-name {
  font-weight: normal;
  margin-bottom: 0px !important;
}

h2.v-h2 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: $vc-blue;
}
h3.v-h3 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: $vc-blue;
}


.vc-content-section {
  background-color: white;
  margin-bottom: 22px;
}

.vc-content-header {
  font-size: 1.3rem;
  margin-top: 0px;
  padding: 6px;
  background-color: $vc-green;
  color: white;
  font-weight: bold;
  margin-bottom: 6px !important;
}

.vc-content-p {
  padding: 12px;
}

.vc-photo-banner {
  position: absolute;
  top: 25px;
  left: 0;
  word-wrap: break-word;
  color: white;
  font-size: 1.3rem;
  letter-spacing: -1px;
  background: rgb(0, 0, 0); /* fallback color */
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 32px 10px 10px;
  line-height: 110%;
  white-space: normal;
  text-align: left;
}

.vc-date-banner {
  position: absolute;
  bottom: 10px;
  color: black;
  font-size: .7rem;
  background: black;
  background: white; /* fallback color */
  background: rgba(211, 211, 211, 0.7);
  padding: 2px 8px 2px 8px;
  margin-left: 10px;
  border: 1px dotted slategray;
  line-height: 200%;
}

.v-iconbox {
  width: 26px;
  height: auto;
  margin-right: 10px;
  color: $vc-blue;
}

.vc-edit-vacation-button {
  background-color: ghostwhite;
}

.vc-photo-button {
  padding-left: 0px !important;
  padding-right: 0px !important
}

.vc-edit-vacation-icon {
  fill: $vc-green;
}

.v-dates {
  margin-left: 0px;
}

.v-badges {
  margin-top: 6px;
  margin-bottom: 6px;
}

.tag-badge {
  background-color: #505050;
  color: white;
  padding: 4px 10px 6px 10px;
}

.hashtag-badge {
  background-color: $vc-blue;
  color: white;
  padding: 4px 10px 6px 10px;
}

.vc-box-container {
  margin-left: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 4px;
}

.vc-box-container-photo {
  margin-left: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 4px;
  align-self: flex-start;
}

.vc-content-box {
  box-shadow: 0px 1px 3px 0px $vc-green-boxshadow;
  padding: 6px 8px 8px 8px;
  box-sizing: border-box;
  border: 1px solid $vc-blue-light95;
  background-color: white;
}

.vc-photo-square {
  margin-left: 8px;
  margin-right: 8px;
  background-color: white;
  position: relative;
  max-width: 360px;
  max-height: 360px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
}

.vc-img-insta {
  width: 100%;
  height: auto;
  min-width: 320px;
  min-height: 320px;
  max-width: 360px;
  max-height: 360px;
}

.v-summary {
  display: inline-block;
  min-width: 280px;
}

.v-notes-linebreak {
  white-space: pre-wrap;
}

.vc-empty-section {
  padding: 12px;
  color: rgba(0, 0, 0, 0.38);
  background-color: ghostwhite;

}

/* Top Menu */
#vc-nav > li.nav-item > a.nav-link {
  padding-right: .5rem;
}

/* Sidebar hamburger menu */
#vacation-sidebar.show {
    display: block;
    z-index: 1000;
    position: absolute;
    width: 360px;
    height: 100%;
    overflow-x: auto;
    overflow-y: visible;
}

/* Dashboard Styles */
.dash-narrow {
  min-width: 300px;
  max-width: 400px;
}
.dash-box {
  min-width: 300px;
  max-width: 400px;
}
.dash-statbox {
  display: inline-block;
  width: 80px;
  height: 60px;
  background-color: $vc-green;
  text-align: center;
  padding: 6px;
  margin-right: 6px;
}
.dash-stat {
  font-size: 16pt;
  font-weight: bold;
  color: yellow;
}
.dash-statlabel {
  font-size: 9.5pt;
  font-weight: normal;
  color: white;
}
.dash-box-title {
  font-weight: bold;
}
.dash-box-upcoming {
  background-color: thistle;
}
.dash-box-map {
  background-color: skyblue;
}
.dash-box-balance {
  background-color: palegoldenrod;
}
.dash-box-ads {
  background-color: lightsalmon;
}
.dash-box-recent {
  background-color: honeydew;
}
.dash-box-types {
  background-color: lightgrey;
}

.field-error-message {
  text-align: right;
  padding-right: 68px;
  font-size: 16px;
  color: #a10000;
}

.vc-loading {
  background-color: ghostwhite;
  color: darkslategray;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 36px 26px;
  margin: 12px 12px 400px 12px;
  border: 1px dotted gray;
  text-align: center;
  letter-spacing: 5px;
}

.vc-feature-info-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid slategray;
  background-color: ghostwhite;
  padding: 18px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 50px;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  max-width: 500px;
}

.vc-disclaimer {
  font-size: .8rem;
  color: darkslategray;
}
